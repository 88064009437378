declare const $: any;
import BookmarkResource from './api/bookmark.resource.js'
import { Spinner } from '../modules/spinner'

export class BookmarkListPage {
    spinner: Spinner = new Spinner();
    EDIT = "Edit";
    DONE = "Done";
    callingEndpoint = false;

    constructor() {
        $(".empty-bookmark-container").hide();
        this.loadBookmarks();
        this.loadEnableEditEvent();
    }

    loadBookmarks() {
        BookmarkResource
            .getBookmarksForCurrentUser()
            .then(this.onGetBookmarksSuccess.bind(this))
            .catch(this.onGetBookmarksError.bind(this));
    }

    onGetBookmarksSuccess(data) {
        if (data.length > 0) {
            $(".empty-bookmark-container").hide();
            $(".bookmark-list").show();

            $(".bookmark-item-container").html("");
            $(".bookmark-count").html(data.length);
            data.forEach(element => {
                const template = this.getItemTemplate(element);
                $(".bookmark-item-container").append(template);
            });
            this.spinner.renderSpinner();
            this.loadDeleteEvents();
        } else {
            $(".empty-bookmark-container").show();
            $(".bookmark-list").hide();
        }
    }

    onGetBookmarksError(error) {
        console.log(error);
    }

    getItemTemplate(bookmark) {
        let deleteButtonStatus = '';
        if ($('.btn-edit-bookmarks').text() === this.EDIT) {
            deleteButtonStatus = 'style="display: none;';
        }

        const template = `
            <article class="article article--list">
			    <figure class="article__figure article--list__figure">
				    <a href="${bookmark.Url}"><img class="article__img article--list__img  b-lazy b-loaded" src="${bookmark.Image}"></a>
			    </figure>

                <section class="article--list__contentWrapper">

                        <p class="article__text article--list__text">${bookmark.ContentDate}</p>

                        <header class="article__header article--list__header">
                            <h3 class="article__headline">
                                <a href="${bookmark.Url}">
                                    <span>${bookmark.Title}</span>
                                </a>
                            </h3>
                        </header>

                </section>
                <a class="btn-delete-bookmark btn-delete-${bookmark.BookmarkId}" data-bookmark-id="${bookmark.BookmarkId}" ${deleteButtonStatus}">
                    X
                </a>
                <div class="lds-ring loader-${bookmark.BookmarkId}"></div>
	        </article>


`;
        return template;
    }

    loadEnableEditEvent() {
        $('.btn-edit-bookmarks').click(this.onBtnEditBookmarksClick.bind(this));
    }

    loadDeleteEvents() {
        $('.btn-delete-bookmark').click(this.onBtnDeleteBookmarkClick.bind(this));
    }

    onBtnDeleteBookmarkClick(e) {

        if (this.callingEndpoint) {
            e.stopImmediatePropagation();
            e.preventDefault();
            return;
        }

        this.callingEndpoint = true;

        const bookmarkId = e.currentTarget.dataset.bookmarkId;

        this.initSpinnerAnimation(bookmarkId);

        BookmarkResource
            .deleteBookmark(bookmarkId)
            .then(this.onDeleteBookmarkSuccess.bind(this))
            .catch(this.onDeleteBookmarkError.bind(this));
    }

    onDeleteBookmarkSuccess(data) {
        $('.btn-bookmark').removeClass('bookmark-active');
        this.callingEndpoint = false;
        this.loadBookmarks();
    }

    onDeleteBookmarkError(error) {
        console.log("error deleting");
        console.log(error);
        this.callingEndpoint = false;
    }

    onBtnEditBookmarksClick() {
        if ($('.btn-edit-bookmarks').text() === this.EDIT) {
            this.activateEditMode();
        } else {
            this.activateDoneMode();
        }
    }

    activateDoneMode() {
        $('.btn-edit-bookmarks').text(this.EDIT);
        $('.btn-delete-bookmark').hide();
    }

    activateEditMode() {

        $('.btn-edit-bookmarks').text(this.DONE);
        $('.btn-delete-bookmark').show();
    }

    initSpinnerAnimation(bookmarkId) {
        $(".btn-delete-" + bookmarkId).hide();
        this.spinner.showSpinner(".loader-" + bookmarkId);
    }
}
