import axios from 'axios';

let BookmarkResource = {
    deleteBookmark,
    saveBookmark,
    getBookmarksForCurrentUser
};

function _extractData(response) {
    return response.data;
}

function getBookmarksForCurrentUser(bookmarkId) {
    return axios.get('/BookmarkApi/GetByUserId').then(_extractData.bind(this));
}

function deleteBookmark(bookmarkId) {
    const bookmark = {
        BookmarkId: bookmarkId
    }
    return axios.post('/BookmarkApi/Delete', bookmark).then(_extractData.bind(this));
}

function saveBookmark(contentId) {
    const bookmark = {
        ContentId: contentId
    }
    return axios.post('/BookmarkApi/Insert', bookmark).then(_extractData.bind(this));
}

export default BookmarkResource
