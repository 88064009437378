declare const $: any;
declare let BookmarkId: any;
declare let ContentId: any;
import BookmarkResource from './api/bookmark.resource.js'
import { Spinner } from '../modules/spinner'

export class BookmarkDetailPage {
    spinner: Spinner = new Spinner();
    DELETE = 'delete';
    SAVE = 'save';
    callingEndpoint = false;

    constructor() {
        this.loadEvents();
    }

    loadEvents() {
        $('.btn-bookmark').click(this.onBtnBookmarkClick.bind(this));
    }

    onBtnBookmarkClick(e) {

        if (this.callingEndpoint) {
            e.stopImmediatePropagation();
            e.preventDefault();
            return;
        }

        this.callingEndpoint = true;

        const bookmarkOperation = this.defineBookmarkOperation();

        switch (bookmarkOperation) {

            case this.DELETE:
                this.deleteBookmark();
                break;
            case this.SAVE:
                this.saveBookmark();
                break;
        }
    }

    defineBookmarkOperation(): string {
        
        if ($('.btn-bookmark').hasClass('bookmark-active')) {
            return this.DELETE;
        } else {
            return this.SAVE;
        }
    }

    deleteBookmark() {
        this.initSpinnerAnimation();
        BookmarkResource
            .deleteBookmark(BookmarkId)
            .then(this.onDeleteBookmarkSuccess.bind(this))
            .catch(this.onDeleteBookmarkError.bind(this));
    }

    saveBookmark() {
        this.initSpinnerAnimation();
        BookmarkResource
            .saveBookmark(ContentId)
            .then(this.onSaveBookmarkSuccess.bind(this))
            .catch(this.onSaveBookmarkError.bind(this));
    }

    onDeleteBookmarkSuccess(data) {
        BookmarkId = 0;
        $('.btn-bookmark').removeClass('bookmark-active');
        this.callingEndpoint = false;
        this.stopSpinnerAnimation();
    }

    onDeleteBookmarkError(error) {
        console.log("error deleting");
        console.log(error);
        this.callingEndpoint = false;
        this.stopSpinnerAnimation();
    }

    onSaveBookmarkSuccess(data) {
        BookmarkId = data.BookmarkId;
        $('.btn-bookmark').addClass('bookmark-active');
        this.callingEndpoint = false;
        this.stopSpinnerAnimation();
    }

    onSaveBookmarkError(error) {
        console.log("error saving");
        console.log(error);
        this.callingEndpoint = false;
        this.stopSpinnerAnimation();
    }

    initSpinnerAnimation() {
        this.spinner.showSpinner();
        $('.btn-bookmark').hide();
    }

    stopSpinnerAnimation() {
        this.spinner.hideSpinner();
        $('.btn-bookmark').show();
    }
}
