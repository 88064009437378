import { nodeListToArray } from './helpers/nodeListToArray';
import { Accordion } from './modules/accordion';
import { Gallery } from './modules/gallery';
import { BookmarkDetailPage } from './bookmark/bookmark-detail-page'
import { BookmarkListPage } from './bookmark/bookmark-list-page'

(() => {

    // Accordion Init
    let accordions = new Accordion({    
        oneOpenAtATime: true
    });



    // Gallery Init
    // Note: it is recommended to use loop: false when creating a gallery with videos at the beginning/end because of duplicated slides.
    let galleryClass = 'single-item-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));
    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);
        if (gallery.classList.contains('article-swiper')) {
            new Gallery({
                containerClass: `${galleryClass}-${index}`,
                slidesPerView: 1,
                slidesPerGroup: 1,
                loop: false,
                autoplay: gallery.getAttribute('data-total-slides') > 1 ? true : false,
                autoHeight: true,
                breakpoints: {
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 95
                    },
                    // when window width is >= 1023px
                    1023: {
                        slidesPerView: 2,
                        spaceBetween: 95
                    },
                    // when window width is >= 1280px
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 95
                    },
                    
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,

                }
            });
        }
        else {
            new Gallery({
                containerClass: `${galleryClass}-${index}`,
                slidesPerView: 1,
                loop: gallery.getAttribute('data-total-slides') > 1 ? true : false,
                autoplay: gallery.getAttribute('data-total-slides') > 1 ? true : false
            });
        }
    });

    // If the page has the Bookmark button we initialize the component
    if (document.querySelector('.btn-bookmark')) {
        let bookmark = new BookmarkDetailPage();
    }

    if (document.querySelector('.bookmark-list')) {
        let bookmarkListPage = new BookmarkListPage();
    }

})();

