declare const $: any;

export class Spinner {
    config: SpinnerConfig;

    constructor(config=null) {
        if (!config) {
            config = { selector: '.lds-ring' };
        }
        this.config = config;
        this.renderSpinner();
    }

    public showSpinner(selector = this.config.selector) {
        return $(selector).show();
    }

    public hideSpinner(selector = this.config.selector) {
        return $(selector).hide();
    }

    public renderSpinner() {
        $(this.config.selector).hide();
        $(this.config.selector).html(this.getSpinnerTemplate());
    }

    getSpinnerTemplate() {
        return `<div></div><div></div><div></div><div></div>`;
    }
}

export interface SpinnerConfig {
    selector: string;
}
